@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --primary-color: #5a5a5ac5;
  --secondary-color: #a7a7a7;
  --accent-color: #6cbb3e;
  --text-color: white;
  --background-color: rgba(0, 0, 0, 0.8);
  --hover-background-color: rgba(255, 255, 255, 0.3);
  --hover-text-color: #333;
  --box-shadow: 0 4px 8px rgba(218, 110, 83, 0.1);
  --font-family: 'Roboto', sans-serif;
}

.header-container {
  width: 100%;
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  font-family: var(--font-family);
  box-shadow: var(--box-shadow);
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  font-size: 1.5rem;
  font-weight: 500;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 15px;
}

.navbar-brand {
  display: flex;
  align-items: center;
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.brand-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 1;
  word-wrap: break-word;
  overflow: hidden;
}

.navbar-brand h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
}

.navbar-brand p {
  font-size: 0.75em;
  margin: 0;
}

.navbar-brand:hover {
  color: var(--accent-color);
}

.logo-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 110px;
  border: 1px solid var(--text-color);
  box-shadow: 0 0 30px rgb(243, 243, 243);
  border-radius: 50%;
  overflow: hidden;
  margin: 0 25px;
  transition: transform 0.3s ease;
}

.logo-frame:hover {
  transform: scale(1.1);
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.phone-number {
  color: var(--text-color);
  font-size: 1rem;
  font-weight: 400;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
}

.phone-number:hover {
  background-color: var(--hover-background-color);
  color: var(--hover-text-color);
}

.phone-icon {
  margin-right: 10px;
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.phone-number:hover .phone-icon {
  transform: rotate(20deg);
}

.phone-number a {
  text-decoration: none;
  color: inherit;
}

.phone-number a:hover {
  text-decoration: underline;
}

.menu-toggle {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-color);
  margin-left: 20px;
}

.menu {
  display: none;
  justify-content: space-around;
  align-items: center;
}

.desktop-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-link,
.menu .nav-link,
.menu button {
  color: var(--text-color);
  text-decoration: none;
  padding: 10px 20px;
  transition: color 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
}

.nav-link:hover,
.menu .nav-link:hover,
.menu button:hover {
  color: var(--accent-color);
}

.menu button:disabled {
  color: rgba(255, 255, 255, 0.6);
  cursor: not-allowed;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
  }

  .navbar-content {
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 0 15px;
  }

  .navbar-brand {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .brand-text {
    text-align: center;
    margin: 10px 0;
    padding: 0 15px;
  }

  .logo-frame {
    width: 100px;
    height: 80px;
    margin: 10px 0;
  }

  .menu-toggle {
    display: block;
    margin-top: 10px;
  }

  .desktop-menu {
    display: none;
  }

  .menu {
    display: none;
    flex-direction: column;
    width: 100%;
    background: var(--background-color);
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 10;
  }

  .menu.open {
    display: flex;
  }

  .menu .nav-link,
  .menu button {
    width: 100%;
    text-align: center;
    padding: 15px 0;
  }
}

@media (max-width: 480px) {
  .navbar {
    font-size: 1rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .navbar-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 15px;
  }

  .navbar-brand {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .brand-text {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 10px 0;
    padding: 0 15px;
  }

  .brand-text h2 {
    font-size: 1.2em;
  }

  .brand-text p {
    font-size: 0.8em;
    white-space: normal;
  }

  .logo-frame {
    display: none;
  }

  .menu-toggle {
    font-size: 1.2rem;
  }
}
