@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box;
    /* Apply border-box to all elements */
}

body,
html {
    display: flex;
    flex-direction: column;
    /* Inhalt wird vertikal gestapelt */
    min-height: 100vh;
    /* Gesamthöhe des Viewports */
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(to right, #c9967473, #fcfcfc);
    width: 100%;
    color: #333;
    overflow-x: hidden;
    /* Prevent horizontal scroll */
}

.full-height {
    flex-grow: 1;
    /* Nimmt den verfügbaren Platz ein */
    display: flex;
    /* Optional: Ermöglicht zentrierten Inhalt */
    flex-direction: column;
    /* Inhalt wird vertikal gestapelt */
    justify-content: flex-start;
    /* Inhalt oben ausrichten */
    min-height: calc(100vh - 100px);
    /* 100px = geschätzte Höhe des Footers */
}





.common-width,
.content-container {
    max-width: 70%;
    margin: 0 auto;
}

.content-container {
    /*Betrifft alle restlichen Seiten außer der ersten*/
    margin: 30px auto;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(245, 245, 245, 0.8));
    padding: 50px;
    border-radius: 15px;
    box-shadow: 0 12px 45px rgba(0, 0, 0, 0.15), 0 16px 60px rgba(0, 0, 0, 0.1), inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content-container h2 {
    color: #4a4a4a;
    font-size: 1.5rem;
    margin-top: 0;
}

.content-container p,
.content-container ul {
    font-size: 1rem;
    line-height: 1.6;
}

.content-container ul {
    padding-left: 20px;
}

.footer {
    margin-top: auto;
    width: 100%;
    padding: 20px;
    background: #5a5a5ac5;
    color: white;
    text-align: center;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.card {
    text-align: center;
}

.card-bottom-margin {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(245, 245, 245, 0.8));
    border-radius: 15px;
    padding: 30px;
    /* Erhöhtes Padding für mehr Raum */
    box-shadow: 0 12px 45px rgba(0, 0, 0, 0.15), 0 16px 60px rgba(0, 0, 0, 0.1), inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-top-margin,
.card-bottom-margin {
    margin-top: 20px;
}

.text-center {
    text-align: center;
    background: linear-gradient(135deg, rgba(200, 230, 201, 0.8), rgba(255, 255, 255, 0.8));
    /* Grüne Grundfarbe */
    border-radius: 15px;
    padding: 30px;
    /* Erhöhtes Padding für mehr Raum */
    box-shadow: 0 12px 45px rgba(0, 0, 0, 0.15), 0 16px 60px rgba(0, 0, 0, 0.1), inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.carousel-item img {
    margin: 0 auto;
    border-radius: 15px;
    box-shadow: 0 12px 45px rgba(0, 0, 0, 0.15), 0 16px 60px rgba(0, 0, 0, 0.1), inset 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 700px;
    object-fit: cover;
}

.carousel-container {
    position: relative;
    margin: 30px auto;
    border-radius: 15px;
    box-shadow: 0 12px 45px rgba(0, 0, 0, 0.15), 0 16px 60px rgba(0, 0, 0, 0.1), inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.carousel-textbox {
    position: absolute;
    top: 10%;
    right: 10%;
    background-color: rgba(206, 103, 110, 0.829);
    color: black;
    padding: 10px;
    border-radius: 5px;
    z-index: 10;
    width: 35%;
    text-align: center;
    word-wrap: break-word;
    overflow: hidden;
}

.map-opening-card-container {
    display: flex;
    justify-content: center;
    margin: 30px auto;
    padding: 10px;
    width: 80%;
    /* Option 5: Breite auf 80% der Breite des Viewports setzen */
}

.map-opening-card {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background: linear-gradient(135deg, rgba(200, 230, 201, 0.8), rgba(255, 255, 255, 0.8));
    /* Grüne Grundfarbe */
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 12px 45px rgba(0, 0, 0, 0.15), 0 16px 60px rgba(0, 0, 0, 0.1), inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.map-section {
    flex: 1;
    padding: 10px;
    display: flex;
    width: 30vw;
    align-items: stretch;
}

.map-section iframe {
    flex: 1;
}

.opening-times-section {
    flex: 1;

    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(129, 212, 250, 0);
}

.opening-times-section p {
    font-size: small;
}

.opening-times-section h5 {
    font-weight: bold;
}

.opening-hours-table {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
}

.row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.address {
    flex: 0 0 auto;
    width: 120px;

}

.day {
    flex: 0 0 auto;
    width: 120px;
    font-weight: bold;
}

.hours {
    flex: 1;
}



/*ACCORDEON SEITE STELLEN*/
/* Ensure the Accordion maintains consistent width in all states */








/* Media queries for responsiveness */
@media (max-width: 1024px) {

    .common-width,
    .content-container, 
    .container-fluid {
        max-width: 90%;
    }

    .content-container h2 {
        font-size: 1.25rem;
    }

    .content-container p,
    .content-container ul {
        font-size: 0.9rem;
    }

    .footer {
        padding: 15px;
        font-size: 0.9rem;
    }
}

@media (max-width: 768px) {
    .row {
        flex-direction: column;
        align-items: flex-start;
    }

    .day {
        flex: none;
        width: auto;
        margin-bottom: 5px;
    }

    .hours {
        width: 100%;
    }

    .common-width,
    .content-container,
    .container-fluid {
        max-width: 95%;
        padding: 10px;
    }

    .content-container h2 {
        font-size: 1.1rem;
    }

    .content-container p,
    .content-container ul {
        font-size: 0.85rem;
    }

    .carousel-textbox {
        top: 20%;
        right: 5%;
        width: 80%;
        padding: 5px;
    }

    .map-opening-card {
        flex-direction: column;
        width: 90%;
    }

    .map-section,
    .opening-times-section {
        width: 100%;
    }

    .footer {
        padding: 10px;
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {

    .common-width,
    .content-container,
    .container-fluid {
        max-width: 95%;
        padding: 15px;
    }

    .content-container h2 {
        font-size: 1rem;
    }

    .content-container p,
    .content-container ul {
        font-size: 0.9rem;
    }

    .carousel-textbox {
        top: 25%;
        right: 5%;
        width: 90%;
        padding: 5px;
    }

    .carousel-container {
        height: 30vh;
    }

    .carousel-item img {
        height: 30vh;
    }

    .map-opening-card-container {
        padding: 1px;
        width: 100%;
        max-width: none;
        min-width: auto;
    }

    .map-opening-card {
        padding: 0;
        /* Entfernt das Padding von der Karte */
    }

    .map-section {
        display: flex;
        width: 100%;

        align-items: stretch;
        padding: 0;
        /* Entfernt das Padding innerhalb des Map-Sektion */
    }

    .map-section iframe {
        height: 30vh;
        flex: 1;
        border-radius: 15px;
        /* Beibehaltung der abgerundeten Ecken für das iframe */
        margin-bottom: 15%;
    }

    .opening-times-section {
        width: 100%;
    }

    .footer {
        padding: 5px;
        font-size: 0.7rem;
    }

    

}