@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 500;
}

.navbar-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-brand h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
}

.navbar-brand p {
  font-size: 0.75em;
  margin: 0;
}

.navbar-brand:hover {
  color: #5de299;
}

.navbar-container {
  position: fixed;
  top: 0;
  right: 0;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  justify-content: baseline;
  padding-top: 190px;
}

.nav-pills {
  width: 100%;
}

.nav-pills .nav-link {
  color: white;
  background-color: rgba(134, 134, 134, 0.979);
  border: 1px solid rgba(221, 168, 88, 0.8);
  border-radius: 5px 0 0 5px;
  margin: 0;
  text-align: left;
  width: 100%;
}

.nav-pills .nav-link:not(.active):hover {
  background-color: rgba(238, 182, 77, 0.8);
}

.nav-pills .nav-link:focus {
  box-shadow: none;
}

.nav-pills .nav-link.active {
  background-color: #6cbb3e;
}

.nav-pills .nav-link:disabled {
  background-color: rgba(141, 141, 141, 0.616);
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .navbar-container {
    display: none; /* Hide the navbar container on mobile devices */
  }
}
